import React, { useContext, useState } from "react"
import Breadcrumbs from "../breadcrumbs"
import { graphql, useStaticQuery } from "gatsby"
import {
  Accordion,
  Col,
  Container,
  Row,
  useAccordionButton,
} from "react-bootstrap"
import AccordionContext from "react-bootstrap/AccordionContext"
import parse from "html-react-parser"
import { useGetJobsQuery } from "../../redux/mediacore/jobs"
import FormJobs from "./jobsForm"

const Main = () => {
  const { realEstate } = useStaticQuery(graphql`
    query {
      realEstate {
        env {
          CLIENTID
        }
        keys {
          captcha
        }
        name
        sections {
          joinUs {
            image
            subtitle
            formText
          }
        }
      }
    }
  `)

  const { env, keys, sections } = realEstate

  const { data: allJobsData } = useGetJobsQuery(env.CLIENTID)

  const ctaBtn = jobId => (
    <>
      <button className="btn btn-primary" onClick={() => handleClick(jobId)}>
        Postularme
      </button>
    </>
  )

  const [showForm, setShowForm] = useState(false)

  const ContextAwareToggle = ({ children, eventKey, callback, formState }) => {
    const { activeEventKey } = useContext(AccordionContext)

    const decoratedOnClick = useAccordionButton(eventKey, () => {
      callback && callback(eventKey)
      formState?.showForm && formState?.setShowForm(false)
    })

    const isCurrentEventKey = activeEventKey === eventKey

    return (
      <div
        className="accordion-header d-flex justify-content-between align-itmes-center cursor-pointer card-header-wrapper"
        onClick={decoratedOnClick}
      >
        {children}
        <i
          className={
            "m-1 icon-arrow-light " + (isCurrentEventKey ? "up" : "down")
          }
        ></i>
      </div>
    )
  }

  const handleClick = jobId => {
    new Promise(resolve => {
      setShowForm(true)
      resolve()
    }).then(() => {
      setTimeout(() => {
        if (document) {
          document
            ?.querySelector(`#form-id-${jobId}`)
            ?.scrollIntoView({ behavior: "smooth", block: "center" })
        }
      }, 500)
    })
  }

  return (
    <section className="sumate-main">
      <Container fluid className="content-main">
        <Row className="mt-4">
          <Col xs={12}>
            <Breadcrumbs
              props={[
                { name: realEstate.name, route: "/", location: "" },
                { name: "Sumate", route: "", location: "" },
              ]}
            />
          </Col>
        </Row>
        {sections?.joinUs?.image ? (
          <img src={sections.joinUs.image} alt="sumate" />
        ) : null}
        <Row className="mx-3">
          <Col xs={12} className="ps-0">
            <h1 className="pb-lg-5 pt-5">
              Sumate al equipo de {realEstate.name}
            </h1>
          </Col>
          <Col xs={12} className="ps-0">
            <h2 className="pb-lg-3">{sections?.joinUs?.subtitle}</h2>
          </Col>
          <Col xs={12} className="ps-0">
            <p className="counter mt-4">
              {allJobsData?.length} búsquedas activas
            </p>
          </Col>
        </Row>
        <div className="jobs-wrapper my-4 mx-3">
          <Accordion flush>
            {allJobsData && allJobsData?.length > 0
              ? allJobsData?.map((job, i) => (
                  <Accordion.Item eventKey={String(i)}>
                    <ContextAwareToggle
                      eventKey={String(i)}
                      formState={{ showForm, setShowForm }}
                    >
                      <div className="d-flex flex-column">
                        <h4 className="position mb-1">{`${job.name} ${
                          job.experience_level !== "Sin especificar"
                            ? `${job.experience_level}`
                            : ""
                        }`}</h4>
                        <h4 className="location">{`${job.mode} ${
                          job.mode === "Presencial" || job.mode === "Híbrida"
                            ? ` -  ${job.location}`
                            : ""
                        }`}</h4>
                      </div>
                    </ContextAwareToggle>
                    <Accordion.Body className="px-0 description">
                      {ctaBtn(job.id)}

                      {job.description && (
                        <div className="description-container my-5">
                          {parse(job.description)}
                        </div>
                      )}
                      {ctaBtn(job.id)}
                      <FormJobs
                        jobData={job}
                        captcha={keys?.captcha}
                        stateProp={showForm}
                        closeBtnAction={() => setShowForm(false)}
                        clientName={realEstate.name}
                        formText={sections?.joinUs?.formText}
                        clientId={realEstate.env.CLIENTID}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                ))
              : null}
          </Accordion>
        </div>
      </Container>
    </section>
  )
}

export default Main
